import { useState } from 'react'
import ModalMoreInformationForm from './ModalMoreInformationForm';
import { Badge } from '../../components/catalyst-component/badge';
import { withTranslation } from 'react-i18next';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

function NewFormCard({ t, completion, type }) {
    const [open, setOpen] = useState(false)

    return (
        <div className="overflow-hidden container-box-b card-btn-slide cursor-pointer"  onClick={() => setOpen(true)}>
            <div className="" >
                <h2 className="font-bold text-gray-900 text-2xl">{completion.parameters?.find(pa => pa.name === "name")?.value}</h2>
                <div className="mt-2 flex gap-1 text-xs">{completion.parameters?.filter(pa => pa.name !== "description" && pa.name !== "name" && pa.value !== null && pa.value !== "" && pa.display)?.map(p => <Badge color="zinc">
                    <span className='text-xs'> {p.value}</span>
                </Badge>)}</div>
                {/* <p className="mt-3 my-1 h-8 overflow-hidden text-xs">{completion.parameters?.find(pa => pa.name === "description")?.value}</p> */}
                <div className="flex w-full justify-end bottom-0 left-0 my-3">
                    <div className="flex items-center btn-slide hover:text-white z-10 " >
                        <ArrowRightIcon className="h-8 w-8 rounded-full p-2 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                        <div className="cursor-pointer " style={{ lineHeight: "16px", fontSize: "16px" }}>
                            {t("En savoir plus")}
                        </div></div>
                </div>
            </div>
            <ModalMoreInformationForm open={open} setOpen={setOpen} type={type} form={completion} />
        </div>
    )
}

export default withTranslation()(NewFormCard);