import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

function getFilteredData(filters, listCompletions) {
    var cands = listCompletions;
    filters?.forEach(filter => {
        const values = filter?.options?.filter(opt => opt.actif)
        if (values && values.length) {
            cands = cands.filter(c => values.find(v => v.value === c.parameters?.find(pa => pa.name === filter.id && pa.filter)?.value));
        }
    });
    return cands
}

function FilterBarCompletion({ t, completions, filters, setFilters }) {

    function onDataChange(data, filtersLoc) {
        var fullFilteredData = getFilteredData(filtersLoc, data)
        var filteredData = data;
        var filteredOptions = []
        filtersLoc.forEach(filterLoc => {
            var options = filterLoc.options.map(opt => {
                var vl = filteredData.filter(fD => fD.parameters?.find(pa => pa.name === filterLoc.id)?.value === opt.value)
                var am = fullFilteredData.filter(fD => fD.parameters?.find(pa => pa.name === filterLoc.id)?.value === opt.value).length
                return { ...opt, hidden: vl.length ? false : true, amount: am }
            })
            filteredOptions.push({
                id: filterLoc.id,
                name: filterLoc.name,
                options: options
            })
            filteredData = getFilteredData(filteredOptions, filteredData)
        })
        setFilters(filteredOptions)
    }


    return filters && filters.length ?
        <div className='hidden xl:block  xl:w-full'>
            <h1 className='text-lg font-semibold  w-full'>Filtres</h1>
            <div className="grid divide-y divide-neutral-200 w-full">

                {filters.filter(f => f.id !== "name" && f.id !== "description").map((section, sectionIdx) => (
                    <div className="py-5">
                        <details className="group" open>
                            <summary className="flex justify-between items-center text-sm cursor-pointer list-none">
                                <span className="text-sm font-semibold leading-6 color-primary-perso">{section.name}</span>
                                <span className="transition group-open:rotate-180">
                                    <svg className='color-primary-perso' fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                                    </svg>
                                </span>
                            </summary>
                            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn  w-full">
                                {section.options.filter(opt => {
                                    return !opt.hidden
                                }).map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center cursor-pointer w-full pb-1">
                                        <input
                                            onChange={() => {
                                                var filtersLocal = filters;
                                                var filterLocal = filters[sectionIdx];
                                                var optionLocal = filterLocal.options[optionIdx];
                                                optionLocal.actif = !optionLocal.actif;
                                                filterLocal.options[optionIdx] = optionLocal;
                                                filtersLocal[sectionIdx] = filterLocal;
                                                onDataChange(completions, filtersLocal)
                                            }}
                                            id={`filter-${section.id}-${optionIdx}`}
                                            name={`${section.id}[]`}
                                            checked={option.actif}
                                            value={option.actif}
                                            type="checkbox"
                                            className="h-4 w-4 border-gray-300 rounded text-indigo-600 cursor-pointer"
                                        />
                                        <label
                                            htmlFor={`filter-${section.id}-${optionIdx}`}
                                            className="ml-3 pr-6 text-sm text-gray-900 wrap cursor-pointer"
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </p>
                        </details>
                    </div>))}
            </div></div> : null
}

export default withTranslation()(FilterBarCompletion);