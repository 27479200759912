import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { createClassForHSForm } from "../utils/requests/form";
import "../Hubspot.css"
import Form from "../utils/forms/handleForm";
import { postCompletion } from "../utils/requests/completions";
import { CompletionsContext, ThemeContext, ContactIdContext, LoadingContext } from "../App"
import { FormTitleContext } from "../layout/Vertical/Global";
import { MsalInstanceContext, VariablesContext } from "../auth/AppProvider";
import { SetTrakingHubspot } from "../utils/functions/trackingHubspot";
import HandleErrorsMessage from "./Completion/HandleErrorsMessage";
import { withTranslation } from "react-i18next";
import { getStripeStatus } from "../utils/requests/stripe";
import { CheckIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

export const FormLoadingContext = React.createContext();

function Completion({ t }) {
    const scrollToTop = useRef()
    const [completion, setCompletion] = useState({});
    const [, setFormTitle] = useContext(FormTitleContext);
    const [step, setStep] = useState(0);
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [pageLoading, setPageLoading] = useContext(LoadingContext);
    const [formLoading, setFormLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    let navigate = useNavigate();
    let { id, checkoutid } = useParams();

    const [completions, setCompletions] = useContext(CompletionsContext);
    const contactId = useContext(ContactIdContext);
    const theme = useContext(ThemeContext);
    const msalInstance = useContext(MsalInstanceContext);
    const variables = useContext(VariablesContext);

    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 250);

        return () => clearTimeout(timer);

    }, []);

    function toTheTop() {
        if (scrollToTop.current) {
            scrollToTop.current.scrollIntoView({ behavior: 'smooth' });
        }
    }


    useEffect(() => {
        createClassForHSForm(theme);
        var completionCurr = completions && completions.find(c => c.id === id);
        var formCurr = {};
        if (completionCurr && completionCurr.step && completionCurr.step[0]) {
            if (checkoutid && checkoutid !== "cancel") {
                setPageLoading(true);
                if (completionCurr.step.find(st => st.questions.find(q => q.label === "Paybox"))) {
                    var index = completionCurr.step.findIndex(st => st.questions.find(q => q.label === "Paybox" && q.fieldType === "custom input"))
                    if (index >= 0 && completionCurr.step[index]) {
                        setStep(index);
                        formCurr = completionCurr.step[index];
                    } else {
                        formCurr = completionCurr.step[0];
                    }
                    setCompletion(completionCurr);
                    setForm(formCurr);
                    setPageLoading(false);
                } else if (completionCurr.step.find(st => st.questions.find(q => q.label === "Stripe"))) {
                    getStripeStatus(msalInstance, variables, completionCurr.dealId, checkoutid).then(res => {
                        if (res) {
                            var index = completionCurr.step.findIndex(st => st.questions.find(q => q.label === "Stripe" && q.fieldType === "custom input"))
                            if (index >= 0 && completionCurr.step[index]) {
                                setStep(index);
                                formCurr = completionCurr.step[index];
                            } else {
                                formCurr = completionCurr.step[0];
                            }
                            setCompletion(completionCurr);
                            setForm(formCurr);
                            setPageLoading(false);
                        } else {
                            navigate("/");
                        }
                    })
                }

            }
            else {
                formCurr = completionCurr.step[0];
                setCompletion(completionCurr);
                setForm(formCurr);
            }
        } else {
            navigate("/")
        }
    }, [id, completions, theme, navigate, t, checkoutid, msalInstance, variables]);

    useEffect(() => {
        if (completion && completion.parameters) {
            setFormTitle(completion?.parameters?.find(p => p.name === "name")?.value)
            SetTrakingHubspot(msalInstance, variables.HSTenantID, variables.ClientName, completion?.parameters?.find(p => p.name === "name")?.value + " - Etape : " + form?.name);
        }
    }, [variables, completion, msalInstance, step, form])

    function handleChangeStep(locstep) {
        setLoading(true);
        if (locstep < 0) {
            locstep = 0
        }
        setStep(() => {
            setForm(completion.step[locstep])
            setLoading(false);
            toTheTop()
            return locstep;
        });
    }


    function handleSubmit(formData) {
        setErrors(false);
        setLoading(true);
        var data = {};
        data.id = completion.id;
        data.step = step;
        data.dealId = completion.dealId;
        data.contactId = contactId;
        var cand = completion;
        cand.step.forEach(step => {
            step.questions.forEach(question => {
                if (formData[question.name]) {
                    question.value = formData[question.name]
                }
            })
        })
        data.formData = cand;
        return postCompletion(msalInstance, variables, data).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                setErrors(["Une erreur est survenue. Veuillez rafraîchir la page et réessayer."]);
                setLoading(false);
                return false
            }
        }).then(res => {
            if (res) {
                try {
                    var index = completions.findIndex(c => c.id === res.id);
                    var completionsLocal = completions;
                    completionsLocal[index] = res;
                    setCompletions(completionsLocal)
                    setCompletion(res);
                    setStep(s => {
                        var newStep = res?.step[s + 1]
                        if (s + 1 >= completion.step.length) {
                            navigate("/");
                        } else {
                            if (newStep?.questions?.find(q => q?.fieldType === "custom input" && q?.objectId === "Logic")) {
                                newStep?.questions.filter(q => q?.fieldType === "custom input" && q?.objectId === "Logic").forEach(q => {
                                    q?.conditionalList?.forEach(cl => {
                                        var stepCL = Object.assign({}, res.step.find(s => s?.questions?.find(sq => sq.id === cl.block)));
                                        var questionCL = Object.assign({}, stepCL?.questions?.find(sq => sq?.id === cl?.block));
                                        if (!newStep?.questions.find(q => q?.id === questionCL?.id)) {
                                            questionCL.hidden = true;
                                            questionCL.readonly = true;
                                            questionCL.required = false;
                                            questionCL.conditional = true;
                                            questionCL.conditionalList = [cl];
                                            newStep?.questions.push(questionCL)
                                        } else {
                                            var questionNIndex = newStep?.questions?.findIndex(q => q.id === questionCL.id);
                                            var questionN = newStep?.questions[questionNIndex]
                                            if (questionNIndex > -1) {
                                                newStep?.questions?.splice(questionNIndex, 1);
                                            }
                                            questionN?.conditionalList?.push(cl);
                                            newStep.questions.splice(questionNIndex, 0, questionN);
                                        }

                                    })
                                })
                            }
                            var questions = [];
                            newStep?.questions.forEach(q => {
                                if (q.fieldType !== "custom input" && q.optionsCustomList?.length !== 1 && completion?.step?.find(s => s?.questions?.find(qq => qq.name === q.name && qq.id !== q.id))) {
                                    q.value = completion?.step?.find(s => s?.questions?.find(qq => qq.name === q.name))?.questions?.find(qq => qq.name === q.name && qq.id !== q.id)?.value;
                                }
                                questions.push(q)
                            })
                            newStep.questions = questions
                            setForm(newStep)
                            setLoading(false);
                            return s + 1;
                        }
                    });
                    return true
                }
                catch (e) {
                    navigate("/");
                }
            } else {
                return false
            }
        }).catch(err => {
            setErrors(err);
            setLoading(false);
            return false
        })


    }

    return pageLoading || (completion && completion.step && step >= completion.step.length) ? null : <div className={isVisible ? "my-forms-visible layout" : "hidden"}>
        <section className="xl:grid grid-cols-2 xl:gap-8 pb-12 xl:pb-24" ref={scrollToTop}>
            <div className="xl:mt-12 ">
                <div className="flex-auto">
                    <h1 className="page-title">{completion?.parameters?.find(p => p.name === "name")?.value}</h1>
                </div>
                <div className="xl:grid xl:grid-cols-2 xl:gap-5 mt-12 xl:mt-0 w-full relative pr-4 hidden">
                    <div className="">
                        <div className="flow-root">
                            <ul key="listquestion" className="-mb-8 mt-4">
                                {completion?.step?.map((stepL, stepIdx) => (
                                    <li key={stepIdx}>
                                        <div className="relative pb-8">
                                            {stepIdx !== completion?.step.length - 1 ? (
                                                <span aria-hidden="true" className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-800" />
                                            ) : null}
                                            <div className="relative flex space-x-3">
                                                <div>
                                                    {stepIdx < step ? (
                                                        <span
                                                            className='bg-green-500 flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white'

                                                        >
                                                            <CheckIcon className="h-5 w-5 text-white" />
                                                        </span>
                                                    ) : stepIdx === step ? (
                                                        <span
                                                            className='flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white bg-gray-900'

                                                        >
                                                            <QuestionMarkCircleIcon className="h-5 w-5 text-white" />
                                                        </span>
                                                    ) : (
                                                        <span
                                                            className='flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white bg-gray-900'

                                                        >
                                                            <QuestionMarkCircleIcon className="h-5 w-5 text-white" />
                                                        </span>
                                                    )}

                                                </div>
                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                    <div>
                                                        <p className={stepIdx < step ? "text-sm font-bold text-green-500" : (stepIdx === step ? "text-sm font-bold color-primary-perso" : "text-sm")}>
                                                            {stepL.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-12 xl:mb-0 xl:mt-12 xl:mx-4 container-box relative">
                <div>
                    {completion && completion.parameters ? <div className="pt-5 flex gap-2">
                        {step > 0 && <ArrowLeftIcon className="xl:hidden h-6 w-6 text-gray-600 pt-1 cursor-pointer" onClick={() => { handleChangeStep(step - 1) }} />}
                        <h1 style={{ fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 600, marginBottom: ".5rem" }}>{completion.step[step]?.name}</h1>
                    </div> : null}
                    {errors ? <HandleErrorsMessage errors={errors} /> : <></>}
                    <div className="">
                        {form && form.questions ? <FormLoadingContext.Provider value={[formLoading, setFormLoading]}>
                            <div className="z-10">
                                <Form form={form.questions} handleSubmit={handleSubmit} theme={theme} contactId={contactId} dealId={completion.dealId} formId={completion.id} loading={loading ? true : false} msalInstance={msalInstance} currentStep={step} setStep={handleChangeStep} toTheTop={toTheTop} />
                            </div>
                        </FormLoadingContext.Provider> : null}

                        <div className={formLoading || loading ?  "absolute left-0 top-0 bottom-0 right-0 bg-gray-700 text-white z-30 opacity-50 flex justify-center items-center" : "loading-end" }>
                            <div className="loaderlogo" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
}

export default withTranslation()(Completion);