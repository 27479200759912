import React from "react";
import { withTranslation } from 'react-i18next';
import HandleErrorsMessage from "../../pages/Completion/HandleErrorsMessage";
import handleCondition from "./generateForm/handleConditionOnQuestion";
import { getQuestionRender, initResponse } from "./generateForm/questionRender";
import generateYupObject from "./generateForm/yupObject";
import { FieldGroup, Fieldset } from "../../components/catalyst-component/fieldset";
import { Text } from "../../components/catalyst-component/text";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../../components/Animation/LoadingSpinner";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorsAPI: [],
            errors: [],
            form: null,
            formData: {},
            conditionInTheForm: [],
            pressSubmitOnce: false,
            submitOnLoading: false,
            loading: true
        }
        this.generateYupObject = generateYupObject.bind(this);
        this.getQuestionRender = getQuestionRender.bind(this);
        this.initResponse = initResponse.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
    }

    componentDidMount() {
        this.initForm();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.form !== this.props.form) {
            this.initForm();
        }
        if (prevProps.currentStep !== this.props.currentStep) {
            this.setState({ errorsAPI: [], errors:[],pressSubmitOnce: null, submitOnLoading: false });
        }
    }

    initForm() {
        this.setState({ loading: true });
        let form = Object.assign([], this.props.form);
        form = form.filter(q => q.fieldType !== "custom input" || q.objectId === "Logic")
        if (form && form.length) {

            var conditionInTheForm = [];
            for (var index = 0; index < form.length; index++) {
                var question = form[index];
                if (question) {
                    var questionName = question.name
                    if (question.conditional && question.conditionalList) {
                        for (var clIndex in question.conditionalList) {
                            var cl = question.conditionalList[clIndex]
                            var name = null
                            if (cl?.block !== "") {
                                // eslint-disable-next-line no-loop-func
                                name = form.find(q => q.id === cl?.block)?.name
                            } else {
                                cl.block = question.id
                            }
                            for (var sInd in cl?.subs) {
                                var s = cl?.subs[sInd];
                                conditionInTheForm.push({
                                    parent: name ? name : questionName,
                                    question: s,
                                    condition: cl
                                })
                            }
                        }
                    }
                    if (question.sub) {
                        var parentG = form?.find(q => q.id === question.sub)
                        if (parentG.fieldType === "custom input") {
                            var parentsG = form?.filter(q => parentG.conditionalList?.find(c => c?.block === q.id))
                            for (var pIndex in parentsG) {
                                conditionInTheForm.push({
                                    parent: parentsG[pIndex].name,
                                    question: question.id,
                                    condition: parentsG.conditionalList?.find(c => c?.block === question.id)
                                })
                            }
                        } else {
                            if (!conditionInTheForm.find(c => c.parent === parentG.name && c.question === question.id)) {
                                conditionInTheForm.push({
                                    parent: parentG.name,
                                    question: question.id,
                                    condition: {}
                                })
                            }
                        }
                    }

                }
            }
            this.schema = this.generateYupObject(form, null, conditionInTheForm, this.props.t);
            this.setState({ conditionInTheForm })
        }
        this.setState({ form: this.props.form }, () => {
            this.setState({ formData: this.handleDataChange({}) },
                () => this.setState({ loading: false }));

        });
    }

    onDataChange(formData, currentQuestion) {
        const that = this;
        if (that.state.conditionInTheForm && that.state.conditionInTheForm.find(c => c.parent === currentQuestion.name || c?.condition?.and?.block === currentQuestion.id)) {
            var form = Object.assign([], that.props.form);
            form = form.filter(q => q.fieldType !== "custom input")
            that.schema = null;
            var locFormData = that.handleDataChange(formData)
            that.schema = that.generateYupObject(form, locFormData, this.state.conditionInTheForm, this.props.t);
            that.setState({ formData: locFormData });
        } else {
            this.setState({ formData })
        }
        if (currentQuestion.required && that.state.pressSubmitOnce) {
            that.schema.validate(that.state.formData, { abortEarly: false })
                .catch(function (err) {
                    return err;
                })
                .then(err => { that.setState({ errors: err, formData }) });
        }
    }

    handleDataChange(formData) {
        var form = Object.assign([], this.props.form);
        form = form.filter(q => q.fieldType !== "custom input")
        if (form && form.length) {
            for (var index = 0; index < form.length; index++) {
                var question = form[index];
                var formDataPart = this.handleQuestionValueChange(formData, question);
                for (let indexKey = 0; indexKey < Object.keys(formDataPart).length; indexKey++) {
                    var key = Object.keys(formDataPart)[indexKey];
                    formData[key] = formDataPart[key];
                }
            }
        }
        return formData;
    }

    handleQuestionValueChange(formData, question) {
        if (!handleCondition(formData, question, this.state.conditionInTheForm, this.props.form)) {
            delete formData[question.name];
        } else {
            if (!formData[question.name] && formData[question.name] !== 0) {
                var initValue = this.initResponse(question);
                if (initValue !== null) {
                    formData[question.name] = initValue;
                }
            }
        }
        return formData;
    }

    handleSubmitForm() {
        this.props.toTheTop()
        if (!this.state.submitOnLoading) {
            this.setState({ submitOnLoading: true });
            this.setState({ pressSubmitOnce: true });
            this.setState({ errors: null })
            const that = this;
            if (this.props.form.find(f => f.fieldType !== "custom input")) {
                const formData = that.state.formData;
                that.schema.isValid(formData).then(valid => {
                    if (valid) {
                        this.props.handleSubmit(formData).then(res => {
                            if (res) {
                                that.setState({ submitOnLoading: false, pressSubmitOnce: null });
                                that.props.toTheTop()
                            }
                        }).catch(error => {
                            var errorsAPI = {};
                            errorsAPI["errors"] = [error.toString()]
                            that.props.toTheTop()
                            that.setState({ errorsAPI, submitOnLoading: false });
                        });
                    }
                    else {
                        that.schema.validate(formData, { abortEarly: false })
                            .catch(function (err) {
                                return err;
                            })
                            .then(err => {
                                that.setState({ errors: err, submitOnLoading: false })
                            });
                    }
                });
            } else {
                this.props.handleSubmit({}).then(res => {
                    if (res) {
                        that.setState({ submitOnLoading: false, pressSubmitOnce: null });
                        that.props.toTheTop()
                    }
                }).catch(error => {
                    var errorsAPI = {};
                    errorsAPI["errors"] = [error.toString()]
                    that.props.toTheTop()
                    that.setState({ errorsAPI, submitOnLoading: false });
                });
            }
        }
    }

    render() {
        const { t } = this.props;
        return (<>{this.state.loading ? <></> :
            <form className="relative">
                <Fieldset className="main" >
                    <HandleErrorsMessage errors={this.state.errorsAPI && this.state.errorsAPI.length > 0 ? this.state.errorsAPI : (this.state.errors?.errors?.length > 0 ? [t("Veuillez corriger les erreurs de votre formulaire avant de soumettre.")] : null)} />
                    <div className="pb-5 gap-2" key="questionsrender">
                        <FieldGroup>
                            <div>
                                {this.state.form && this.state.form.length ? (
                                    this.state.form.map((question, key) => {
                                        return this.getQuestionRender(question, key);
                                    })
                                ) : null
                                }
                                <div key="submitform mt-7">
                                    <div className="flex items-center justify-between gap-4">
                                        {this.props.currentStep > 0 && <Text
                                            className="cursor-pointer hidden xl:block"
                                            onClick={() => {
                                                this.setState({ errorsAPI: [], pressSubmitOnce: null, submitOnLoading: false });//?? pas apeller
                                                this.props.setStep(this.props.currentStep - 1);
                                            }}
                                        >
                                            {t("Précédent")}
                                        </Text>
                                        }

                                        <div className="xl:flex w-full justify-end bottom-0 left-0 mt-5 hidden">

                                            <div className="flex items-center btn-slide hover:text-white z-10 " onClick={() => !this.state.submitOnLoading ? this.handleSubmitForm() : null}>
                                                <ArrowRightIcon className="h-8 w-8 rounded-full p-2 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                                                <div className="cursor-pointer " style={{ lineHeight: "16px", fontSize: "16px" }}>
                                                    {this.props.loading === true ? <LoadingSpinner /> : t("Save")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex w-full xl:justify-end bottom-0 left-0 mt-5 xl:hidden">

                                            <div className="flex items-center justify-center  text-white  bg-primary-perso w-full  rounded-full cursor-pointer z-10 " onClick={() => !this.state.submitOnLoading ? this.handleSubmitForm() : null}>
                                                <ArrowRightIcon className="hidden  h-8 w-8 rounded-full p-2 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                                                <div className=" py-2 xl:py-0" style={{ lineHeight: "16px", fontSize: "16px" }}>
                                                    {this.props.loading === true ? <LoadingSpinner /> : t("Save")}
                                                </div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </FieldGroup>
                    </div>

                </Fieldset>
            </form>
        }</>);
    }

}

export default withTranslation()(Form);