import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { CompletionsContext, ThemeContext } from "../App"
import { CheckCircleIcon, ExclamationCircleIcon, MinusCircleIcon, PencilIcon } from "@heroicons/react/24/solid";
import { Label } from "./catalyst-component/fieldset";
import { withTranslation } from "react-i18next";

function verifyPath(json, path) {
  var exists = true;
  if (path) {
    var steps = path.split('.');
    if (steps.length > 1) {
      steps.forEach(step => {
        if (json && json[step]) {
          json = json[step];
        } else {
          exists = false;
        }
      })
    } else {
      if (!json) {
        exists = false;
      }
    }
  } else {
    exists = false;
  }
  return exists;
}

function Recap({ currentStep, setStep, t }) {
  const [completion, setCompletion] = useState({});
  const [completions] = useContext(CompletionsContext);
  const theme = useContext(ThemeContext);
  let { id } = useParams();

  useEffect(() => {
    setCompletion(completions && completions.find(c => c.id === id));
  }, [completions, id])

  return completion && <div className="p-3">
    {completion.step && completion.step.length > 0 ? completion.step.filter((s, index) => index < currentStep ? true : false).map((s, index) => {
      var show = false;
      if(s.questions?.find(q => (q.fieldType !== "custom input" || (q.fieldType === "custom input" && q.label === "Stripe")))) { show = true}
      return show && <div className="border border-gray-200 rounded-xl my-3 px-5 py-4 overflow-hidden" key={s.id}>
        <div className="flex justify-between">
          <h2 className="font-medium text-md mb-2">{s.name}</h2>
          <PencilIcon onClick={() => { setStep(index) }} className="cursor-pointer font-bold text-md h-5" style={verifyPath(theme, "secondary_color") ? { color: theme.secondary_color } : null} />
        </div>
        <div>{s.questions && s.questions.length > 0 ? s.questions.map(q => {
          if (q.fieldType !== "custom input" || (q.fieldType === "custom input" && q.label === "Stripe")) {
            var value = "-";
            if (q.value) {
              if (q.fieldType === "file") {
                value = <Label className="cursor-pointer" onClick={() => { window.open(q.value, '_blank'); }} style={verifyPath(theme, "secondary_color") ? { color: theme.secondary_color } : null}>
                  {t("Download uploaded file")}
                </Label>
              } else if (q.fieldType === "custom input" && q.label === "Stripe") {
                value = q.stripe.status === "paid" ? <CheckCircleIcon className="h-5 text-green-500" /> : (q.stripe.status === "waiting" ? <ExclamationCircleIcon className="h-5 text-yellow-500" /> : <MinusCircleIcon className="h-5 text-red-500" />)
              } else {
                if (q.fieldType === "select") {
                  value = q?.options?.find(opt => opt.value === q.value)?.label
                } else if (q.fieldType === "booleancheckbox") {
                  value = q.value === "false" ? t("No") : t("Yes")
                } else if (q.fieldType === "date") {
                  var d = new Date(q.value);
                  value = (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + "/" + (d.getMonth() < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) + "/" + d.getFullYear();
                } else {
                  value = q.value;
                }
              }
            }
            return !q.hidden && <div className="flex justify-between mb-1 text-sm font-medium" key={q.id}>
              <div className="text-gray-400">{q.label}</div>
              <div className="text-gray-500">{value}</div>
            </div>
          } else { return null; }
        }) : null}</div>
      </div>
    }) : null}
  </div>
}

export default withTranslation()(Recap);