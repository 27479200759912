import { withTranslation } from "react-i18next";
import FilterBarCompletion from "./FilterBarCompletion";
import { useEffect, useState } from "react";
import NewFormCard from "./NewFormCard";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";

function getFilteredData(filters, listCompletions) {
    var cands = listCompletions;
    if (filters && filters.length > 0) {
        filters?.forEach(filter => {
            const values = filter?.options?.filter(opt => opt.actif)
            if (values && values.length) {
                cands = cands.filter(c => values.find(v => v.value === c.parameters?.find(pa => pa.name === filter.id && pa.filter)?.value));
            }
        });
    }
    return cands
}


function ListNewCompletions({ t, type, completions, setFilters, filters, setView }) {
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 250);

        return () => clearTimeout(timer);

    }, []);


    return (
        <div className={isVisible ? "my-forms-visible layout" : "hidden"}>

            <section className="2xl:grid 2xl:grid-cols-2">
                <div className="hidden 2xl:block mt-24">
                    <div className="flex-auto ">
                        <h1 className="page-title " dangerouslySetInnerHTML={{ __html: type.description }} />
                    </div>
                </div>
                <div className="relative">
                    <div className="absolute -z-1" style={{ right: "40%" }}>
                        <div className="bg-img bg-img-1" />
                        <div className="bg-img bg-img-2" />
                        <div className="bg-img bg-img-3" />
                        <div className="bg-img bg-img-4" />
                    </div>
                </div>
                <div className="2xl:hidden  z-50 relative ">
                    <div className="grid grid-cols-5 flex-auto absolute justify-between items-center bg-white w-screen py-2 -ml-6 pl-6">
                        <h1 className="page-title col-span-4 pr-12" dangerouslySetInnerHTML={{ __html: type.description }} />
                        <span className="relative inline-block">
                            <AdjustmentsHorizontalIcon onClick={() => { setView("form") }} className="h-6 w-6 text-gray-400 cursor-pointer xl:hidden" />
                            {filters.filter(f=>f.options.find(opt => opt.actif)).length > 0 ? <span className="absolute -bottom-2 left-3 block h-4 w-4 rounded-full bg-primary-perso ring-2 ring-white text-white text-xs px-1">{filters.filter(f=>f.options.find(opt => opt.actif)).length}</span> : null}
                        </span>

                    </div>
                </div>
            </section>
            <div className="xl:grid xl:grid-cols-6 gap-5 h-full w-full 2xl:pr-4 pt-24  md:pt-[15%] 2xl:pt-0 z-1 relative">
                {completions.length > 1 && <div className="bg-white z-1">
                    <FilterBarCompletion completions={completions} setFilters={setFilters} filters={filters} />
                </div>}

                {getFilteredData(filters, completions).length >= 1 ?
                    <div className="xl:col-span-5 xl:mt-0 h-full overflow-y-scroll xl:ml-12 pr-4" >
                        <div className="grid md:grid-cols-2 gap-6">{getFilteredData(filters, completions).slice(0, 2).map((completion, ckey) => (
                            <NewFormCard key={ckey} completion={completion} type={type} />
                        ))}</div><div className="grid md:grid-cols-3 gap-6 mt-3">{getFilteredData(filters, completions).slice(2, getFilteredData(filters, completions).length).map((completion, ckey) => (
                            <NewFormCard key={ckey} completion={completion} type={type} />
                        ))}</div></div>
                    : <div className="flex justify-center items-center w-full mt-12 h-full max-w-7xl mx-auto col-span-3">
                        <div className="text-center">
                            <svg className="mx-auto h-12 w-12 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                            </svg>
                            <h3 className="mt-2 text-sm font-medium text-gray-900">{t("No forms")}</h3>
                        </div>
                    </div>}
            </div>

        </div>
    )
}

export default withTranslation()(ListNewCompletions);