import { HSPipelineContext } from "../../App"
import { useContext, useEffect, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import { withTranslation } from "react-i18next";
import { Badge } from "../../components/catalyst-component/badge";


function MyForms({ type, myCompletions, canCreateANewCompletion, t }) {
    const navigate = useNavigate()
    const hspipelines = useContext(HSPipelineContext);
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 250);

        return () => clearTimeout(timer);

    }, [type]);

    return (
        <div className={isVisible ? 'my-forms-visible layout h-full overflow-x-hidden' : 'hidden'}>
            {/* PC VERSION */}
            <section aria-labelledby="quick-links-title" className="2xl:grid grid-cols-2 gap-6 hidden">
                <div className="flex items-center h-[70vh] mr-12">
                    <div className="flex-auto">
                        <h1 className="page-title " dangerouslySetInnerHTML={{ __html: type.title }} />
                        {canCreateANewCompletion ? <div className="flex mt-5 items-center btn-slide hover:text-white ">
                            <ArrowRightIcon className="h-12 w-12 cursor-pointer rounded-full p-4 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                            <div className="cursor-pointer " onClick={() => navigate("/newform")} style={{ lineHeight: "18px", fontSize: "18px" }}>
                                Démarrer une nouvelle candidature
                            </div></div> : null
                        }
                    </div>
                </div>
                <div className="relative">
                    <div className="absolute -z-1" style={{ right: "40%" }}>
                        <div className="bg-img bg-img-1" />
                        <div className="bg-img bg-img-2" />
                        <div className="bg-img bg-img-3" />
                        <div className="bg-img bg-img-4" />
                    </div>
                    <div className="mt-1 mx-auto flex h-[70vh] items-center z-50 mr-12 mb-6">
                        <div className="home-block-list-form">
                            <div className="home-block-list-form-bg" />
                            {myCompletions && myCompletions.length ?
                                <h2 className="home-block-title">
                                    {type.name}
                                </h2> : null}
                            {myCompletions.map((completion, completionId) => {
                                var cantBeUpdated = false;
                                var stage = (hspipelines.find(pip => pip.id === completion.pipeline.id)?.stages)?.find(st => st.id === completion.hs_pipeline_stage);
                                if (completion.hs_pipeline_stage === completion.hs_pipeline_final) {
                                    cantBeUpdated = true;
                                } else {
                                    var finalStage = hspipelines.find(pip => pip.id === completion.pipeline.id)?.stages?.find(st => st.id === completion.pipeline.end);
                                    if (stage) {
                                        cantBeUpdated = stage.displayOrder >= finalStage.displayOrder;
                                    } else {
                                        cantBeUpdated = true;
                                    }
                                }
                                return <div key={completionId} className={cantBeUpdated ? "form-list" : "form-list cursor-pointer"} onClick={() => !cantBeUpdated ? navigate("/" + completion.id) : null}>
                                    <span className="form-list-title flex items-center justify-start gap-0">
                                        <span className={cantBeUpdated ? "form-list-name text-gray-500" : " form-list-name-active form-list-name"}>
                                            <div className="max-w-[28vw] overflow-hidden truncate">{completion.parameters ? completion.parameters?.find(p => p.name === "name")?.value : "-"}</div>
                                        </span>
                                        <span className="form-list-status m-1 ml-4" style={{ backgroundColor: cantBeUpdated ? "orange" : "green", display: "inline-block" }}>{stage ? stage.label : (completion.hs_pipeline_stage ? completion.hs_pipeline_stage : null)}</span>
                                    </span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </section>
            {/* MOBILE VERSION */}
            <section aria-labelledby="quick-links-title" className="2xl:hidden">
                <div className="relative">
                    <div className="w-full">
                        <div className="mt-6 max-w-7xl mx-auto flex h-[calc(100%_+_45rem)] items-center z-50">
                            <div className="flex-auto ">
                                <h1 className="text-3xl font-bold " dangerouslySetInnerHTML={{ __html: type.title }} />
                                {canCreateANewCompletion ?
                                    <div className="flex mt-5 items-center rounded-full bg-primary-perso w-fit pr-4">
                                        <ArrowRightIcon className="h-6 w-6 cursor-pointer  p-1 mr-2 text-white " aria-hidden="true" />
                                        <div className="cursor-pointer text-white" onClick={() => navigate("/newform")} style={{ lineHeight: "18px", fontSize: "13px" }}>
                                            Démarrer une nouvelle candidature
                                        </div></div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mt-1 max-w-7xl mx-auto h-[calc(100%_+_45rem)] items-center z-50 mr-12 mt-6 pb-6">
                        <div className="grid lg:grid-cols-2 lg:gap-5">
                            {myCompletions.map((completion, completionId) => {
                                var cantBeUpdated = false;
                                var stage = (hspipelines.find(pip => pip.id === completion.pipeline.id)?.stages)?.find(st => st.id === completion.hs_pipeline_stage);
                                if (completion.hs_pipeline_stage === completion.hs_pipeline_final) {
                                    cantBeUpdated = true;
                                } else {
                                    var finalStage = hspipelines.find(pip => pip.id === completion.pipeline.id)?.stages?.find(st => st.id === completion.pipeline.end);
                                    if (stage) {
                                        cantBeUpdated = stage.displayOrder >= finalStage.displayOrder;
                                    } else {
                                        cantBeUpdated = true;
                                    }
                                }
                                return <div className="overflow-hidden container-box-b card-btn-slide cursor-pointer" onClick={() => !cantBeUpdated ? navigate("/" + completion.id) : null}>
                                    <div className="" >
                                        <h2 className="font-bold text-gray-900 text-2xl">{completion.parameters?.find(pa => pa.name === "name")?.value}</h2>
                                        <div className="mt-2 flex gap-1 text-xs"><Badge color={cantBeUpdated ? "amber" : "emerald"}>
                                            <span className='text-xs'>{stage ? stage.label : (completion.hs_pipeline_stage ? completion.hs_pipeline_stage : null)}</span>
                                        </Badge></div>
                                        <p className="mt-3 my-1 h-8 overflow-hidden text-xs">{completion.parameters?.find(pa => pa.name === "description")?.value}</p>
                                        {!cantBeUpdated && <div className="flex w-full justify-end bottom-0 left-0 my-3">
                                            <div className="flex items-center btn-slide hover:text-white z-10 " >
                                                <ArrowRightIcon className="h-8 w-8 rounded-full p-2 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                                                <div className="cursor-pointer " style={{ lineHeight: "16px", fontSize: "16px" }}>
                                                    {t("Continuer")}
                                                </div></div>
                                        </div>}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default withTranslation()(MyForms);